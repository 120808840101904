import { toggleHamburger } from './toggleHamburger';
import { mvSlider } from './sliders';
import { accordion } from './accordion';
import { tabMenu } from './tabMenu';
toggleHamburger('js-hamburger', 'js-spmenu', 1024);
accordion();
tabMenu();

jQuery(() => {
  const loopSliderOptions = {
    slidesPerView: 'auto',
    loop: true,
    allowTouchMove: false,
    speed: 30000,
    autoplay: {
      delay: 0,
    },
  };

  new Swiper('#mv02 .loop-slider', {
    ...loopSliderOptions,
    spaceBetween: 80,
  });

  new Swiper('#about .loop-slider', {
    ...loopSliderOptions,
    speed: 6000,
  });
});

jQuery(document).ready(function () {
  const infScrollSetup = function () {
    const nextPostsLink = document.querySelector('.nextpostslink');
    if (!nextPostsLink) return;
    const infScroll = new InfiniteScroll('#js-infinite-list', {
      append: '.p-home-body__item',
      path: '.nextpostslink',
      hideNav: '.wp-pagenavi',
      button: '#js-more-button',
      scrollThreshold: false,
      status: '.scroller-status',
      // history: 'push',
    });
  };
  infScrollSetup();
});
